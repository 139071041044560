ul.our-service-list {
  list-style: none;
  padding-left: 24px;
  margin-block: 0;
}

ul.our-service-list li::before {
  content: "\2022";
  color: #4a489d;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
