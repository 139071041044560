.post-main-profile {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 1 / 1;
}
.post-first-layer {
  position: relative;
  top: 6px;
  left: 0;
  width: inherit;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 10px;
}
.post-second-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: inherit;
  aspect-ratio: 1 / 1;
  border-radius: 10px;
  background: linear-gradient(0deg, #373737 0%, #00000000 30%, #00000000 100%);
}
.post-third-layer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: inherit;
}
.post-fourth-layer {
  position: absolute;
  top: 11px;
  right: 6px;
  width: inherit;
  height: inherit;
}

/* TIKTOK */
.post-main-profile-tiktok {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 9 / 16;
}
.post-first-layer-tiktok {
  position: relative;
  top: 6px;
  left: 0;
  width: inherit;
  aspect-ratio: 9 / 16;
  object-fit: cover;
  border-radius: 10px;
}
.post-second-layer-tiktok {
  position: absolute;
  bottom: 0;
  left: 0;
  width: inherit;
  aspect-ratio: 9 / 16;
  border-radius: 10px;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, #373737 0%, #00000000 30%, #00000000 100%);
}
.post-third-layer-tiktok {
  position: absolute;
  bottom: 16px;
  left: 0px;
  width: inherit;
}
.post-fourth-layer-tiktok {
  position: absolute;
  top: 11px;
  right: 6px;
  width: inherit;
  height: inherit;
}
