@font-face {
  font-family: 'hashu';
  src:  url('fonts/hashu.eot?iystxa');
  src:  url('fonts/hashu.eot?iystxa#iefix') format('embedded-opentype'),
    url('fonts/hashu.ttf?iystxa') format('truetype'),
    url('fonts/hashu.woff?iystxa') format('woff'),
    url('fonts/hashu.svg?iystxa#hashu') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-hu-"], [class*=" ico-hu-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'hashu' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-hu-gender-lgbt2:before {
  content: "\e910";
}
.ico-hu-content-type-edu:before {
  content: "\e991";
}
.ico-hu-content-type-automobile:before {
  content: "\e900";
}
.ico-hu-content-type-beauty:before {
  content: "\e901";
}
.ico-hu-content-type-entertianment:before {
  content: "\e902";
}
.ico-hu-content-type-fashion:before {
  content: "\e903";
}
.ico-hu-content-type-finance:before {
  content: "\e904";
}
.ico-hu-content-type-food:before {
  content: "\e905";
}
.ico-hu-content-type-gaming:before {
  content: "\e906";
}
.ico-hu-content-type-home:before {
  content: "\e907";
}
.ico-hu-content-type-lifestyle:before {
  content: "\e908";
}
.ico-hu-content-type-parenting:before {
  content: "\e909";
}
.ico-hu-content-type-pet:before {
  content: "\e90a";
}
.ico-hu-content-type-sport:before {
  content: "\e90b";
}
.ico-hu-content-type-technology:before {
  content: "\e90c";
}
.ico-hu-content-type-travel:before {
  content: "\e90d";
}
.ico-hu-content-type-wellness:before {
  content: "\e90e";
}
.ico-hu-gender-female:before {
  content: "\e90f";
}
.ico-hu-gender-male:before {
  content: "\e911";
}
.ico-hu-add:before {
  content: "\e912";
}
.ico-hu-add-circle-outline:before {
  content: "\e913";
}
.ico-hu-add-circle-solid:before {
  content: "\e914";
}
.ico-hu-address-billing:before {
  content: "\e915";
}
.ico-hu-address-shipping:before {
  content: "\e916";
}
.ico-hu-align-left:before {
  content: "\e917";
}
.ico-hu-announcement:before {
  content: "\e918";
}
.ico-hu-arrow-down:before {
  content: "\e919";
}
.ico-hu-arrow-left:before {
  content: "\e91a";
}
.ico-hu-arrow-right:before {
  content: "\e91b";
}
.ico-hu-arrow-up:before {
  content: "\e91c";
}
.ico-hu-attach-file:before {
  content: "\e91d";
}
.ico-hu-bank:before {
  content: "\e91e";
}
.ico-hu-bold:before {
  content: "\e91f";
}
.ico-hu-business:before {
  content: "\e920";
}
.ico-hu-calendar:before {
  content: "\e921";
}
.ico-hu-camera:before {
  content: "\e922";
}
.ico-hu-cancel:before {
  content: "\e923";
}
.ico-hu-cancel-circle:before {
  content: "\e924";
}
.ico-hu-cancel-circle-solid:before {
  content: "\e925";
}
.ico-hu-check:before {
  content: "\e926";
}
.ico-hu-check-circle-line:before {
  content: "\e927";
}
.ico-hu-check-circle-solid:before {
  content: "\e928";
}
.ico-hu-chevron-down:before {
  content: "\e929";
}
.ico-hu-chevron-down-circle:before {
  content: "\e92a";
}
.ico-hu-chevron-left:before {
  content: "\e92b";
}
.ico-hu-chevron-left-circle:before {
  content: "\e92c";
}
.ico-hu-chevron-right:before {
  content: "\e92d";
}
.ico-hu-chevron-right-circle:before {
  content: "\e92e";
}
.ico-hu-chevron-up:before {
  content: "\e92f";
}
.ico-hu-chevron-up-circle:before {
  content: "\e930";
}
.ico-hu-code:before {
  content: "\e931";
}
.ico-hu-comment-dot-line:before {
  content: "\e932";
}
.ico-hu-comment-dots-solid:before {
  content: "\e933";
}
.ico-hu-comment-line-line:before {
  content: "\e934";
}
.ico-hu-credit-card:before {
  content: "\e935";
}
.ico-hu-dashboard-line:before {
  content: "\e936";
}
.ico-hu-dashboard-solid:before {
  content: "\e937";
}
.ico-hu-delete:before {
  content: "\e938";
}
.ico-hu-delete-solid:before {
  content: "\e939";
}
.ico-hu-dots-horizontal:before {
  content: "\e93a";
}
.ico-hu-dots-vertical:before {
  content: "\e93b";
}
.ico-hu-edit:before {
  content: "\e93c";
}
.ico-hu-edit-solid:before {
  content: "\e93d";
}
.ico-hu-email-line:before {
  content: "\e93e";
}
.ico-hu-email-solid:before {
  content: "\e93f";
}
.ico-hu-engagement:before {
  content: "\e940";
}
.ico-hu-engagement-rate:before {
  content: "\e941";
}
.ico-hu-export:before {
  content: "\e942";
}
.ico-hu-eye-circle:before {
  content: "\e943";
}
.ico-hu-eye-circle-solid:before {
  content: "\e944";
}
.ico-hu-facebook:before {
  content: "\e945";
}
.ico-hu-facebook-solid:before {
  content: "\e946";
}
.ico-hu-feedback:before {
  content: "\e947";
}
.ico-hu-filter:before {
  content: "\e948";
}
.ico-hu-filter-solid:before {
  content: "\e949";
}
.ico-hu-heart-line:before {
  content: "\e94a";
}
.ico-hu-heart-solid:before {
  content: "\e94b";
}
.ico-hu-home-line:before {
  content: "\e94c";
}
.ico-hu-home-solid:before {
  content: "\e94d";
}
.ico-hu-ig:before {
  content: "\e94e";
}
.ico-hu-ig-solid:before {
  content: "\e94f";
}
.ico-hu-image:before {
  content: "\e950";
}
.ico-hu-import:before {
  content: "\e951";
}
.ico-hu-italic:before {
  content: "\e952";
}
.ico-hu-like:before {
  content: "\e953";
}
.ico-hu-like-solid:before {
  content: "\e954";
}
.ico-hu-line:before {
  content: "\e955";
}
.ico-hu-link:before {
  content: "\e956";
}
.ico-hu-link-off:before {
  content: "\e957";
}
.ico-hu-list:before {
  content: "\e958";
}
.ico-hu-logout:before {
  content: "\e959";
}
.ico-hu-mail:before {
  content: "\e95a";
}
.ico-hu-mail-solid:before {
  content: "\e95b";
}
.ico-hu-menu:before {
  content: "\e95c";
}
.ico-hu-messenger-line:before {
  content: "\e95d";
}
.ico-hu-messenger-solid:before {
  content: "\e95e";
}
.ico-hu-notification:before {
  content: "\e95f";
}
.ico-hu-notification-solid:before {
  content: "\e960";
}
.ico-hu-organization:before {
  content: "\e961";
}
.ico-hu-payment:before {
  content: "\e962";
}
.ico-hu-people-top-card:before {
  content: "\e963";
}
.ico-hu-phone:before {
  content: "\e964";
}
.ico-hu-photo:before {
  content: "\e965";
}
.ico-hu-play:before {
  content: "\e966";
}
.ico-hu-play-circle:before {
  content: "\e967";
}
.ico-hu-posts:before {
  content: "\e968";
}
.ico-hu-quote-left:before {
  content: "\e969";
}
.ico-hu-quote-right:before {
  content: "\e96a";
}
.ico-hu-rejected:before {
  content: "\e96b";
}
.ico-hu-remove:before {
  content: "\e96c";
}
.ico-hu-remove-circle-outline:before {
  content: "\e96d";
}
.ico-hu-remove-circle-solid:before {
  content: "\e96e";
}
.ico-hu-r-photo-album:before {
  content: "\e96f";
}
.ico-hu-r-video-clip:before {
  content: "\e970";
}
.ico-hu-r-video-photo:before {
  content: "\e971";
}
.ico-hu-s-acc-business:before {
  content: "\e972";
}
.ico-hu-s-acc-creator:before {
  content: "\e973";
}
.ico-hu-s-acc-page:before {
  content: "\e974";
}
.ico-hu-s-acc-personal:before {
  content: "\e975";
}
.ico-hu-save:before {
  content: "\e976";
}
.ico-hu-search:before {
  content: "\e977";
}
.ico-hu-sent:before {
  content: "\e978";
}
.ico-hu-setting-line:before {
  content: "\e979";
}
.ico-hu-setting-solid:before {
  content: "\e97a";
}
.ico-hu-share:before {
  content: "\e97b";
}
.ico-hu-share-solid:before {
  content: "\e97c";
}
.ico-hu-statement-brands:before {
  content: "\e97d";
}
.ico-hu-statement-jobs:before {
  content: "\e97e";
}
.ico-hu-sync:before {
  content: "\e97f";
}
.ico-hu-text-format:before {
  content: "\e980";
}
.ico-hu-tiktok:before {
  content: "\e981";
}
.ico-hu-twitter:before {
  content: "\e982";
}
.ico-hu-twitter-solid:before {
  content: "\e983";
}
.ico-hu-underline:before {
  content: "\e984";
}
.ico-hu-user:before {
  content: "\e985";
}
.ico-hu-user-circle-line:before {
  content: "\e986";
}
.ico-hu-user-circle-solid-in:before {
  content: "\e987";
}
.ico-hu-user-circle-solid-out:before {
  content: "\e988";
}
.ico-hu-video:before {
  content: "\e989";
}
.ico-hu-video-solid:before {
  content: "\e98a";
}
.ico-hu-visability-off-line:before {
  content: "\e98b";
}
.ico-hu-visability-off-solid:before {
  content: "\e98c";
}
.ico-hu-visability-on-line:before {
  content: "\e98d";
}
.ico-hu-visability-on-solid:before {
  content: "\e98e";
}
.ico-hu-youtube:before {
  content: "\e98f";
}
.ico-hu-youtube-solid:before {
  content: "\e990";
}
