#input-file {
  display: none;
}

.label-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    gray;

  width: 150px;
  height: 150px;

  border: 2px dashed #9e9e9e;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 10px;
  color: gray;
}

.react-video-thumbnail-image {
  height: 150px;
  width: 150px;
  overflow: hidden;
  border-radius: 15px;
}
