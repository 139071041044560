.container-main {
  padding: 28px 28px 28px 120px;
}
/* .container-main {
  padding: 88px 28px 28px 120px;
} */
.container-title {
  padding: 100px 28px 28px 120px;
}

.container-main-filter {
  padding: 88px 28px 28px;
}

.requir {
  color: #f44336;
}

.swal-popup{
  min-width:200px;
  width: 300px;
}

.swal2-title{
  font-size: 16px;
}

.swal2-icon{
  font-size: 12px;
}

.swal-confirm-button, .swal-cancel-button{
  margin: 5px;
  height: 36px;
}

@media screen and (min-width:600px){
  .swal-popup{
    width: 400px;
  }
  
  .swal2-title{
    font-size: 20px;
  }
  
  .swal2-icon{
    font-size: 16px;
  }
}