.main-profile {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 1 / 1;
}
.first-layer {
  position: relative;
  top: 4px;
  left: 0;
  width: inherit;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
}
.second-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: inherit;
  aspect-ratio: 1 / 1;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, #373737 0%, #00000000 30%, #00000000 100%);
}
.third-layer {
  position: absolute;
  bottom: 16px;
  left: 20px;
  font-weight: bold;
}
