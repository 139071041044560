header.home-bar {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  top: 12px;
  position: fixed;
  width: 100%;
  z-index: 1100;
}

.visible {
  top: 12px !important;
  transition: top 0.3s ease-out;
}

.hidden {
  top: -120px !important;
  transition: top 0.3s ease-out;
}
