.prompt-font {
  font-family: "Kanit";
}

.swal-z-index {
  z-index: 1399 !important;
}

.swal-popup {
  border-radius: 20px;
  min-width: 512px;
}

.swal-icon {
  margin: 2.4em auto 0.6em;
}
.swal-icon.swal2-success {
  border-color: #4caf50;
  color: #4caf50;
}
.swal-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #4caf50;
}
.swal-icon.swal2-success .swal2-success-ring {
  border: 0.25em solid rgba(76, 175, 80, 0.5);
}
.swal-icon.swal2-error {
  border-color: rgba(244, 67, 54, 0.5);
  color: rgba(244, 67, 54, 0.5);
}
.swal-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: #f44336;
}
.swal-icon.swal2-warning {
  font-family: sans-serif;
  border-color: rgba(251, 140, 0, 0.5);
  color: #fb8c00;
}
.swal-icon.swal2-info {
  font-family: sans-serif;
  border-color: rgba(2, 119, 189, 0.5);
  color: #0277bd;
}
.swal-icon.swal2-question {
  font-family: sans-serif;
  border-color: rgba(140, 73, 156, 0.5);
  color: #8c499c;
}

.swal-title {
  font-family: "Kanit";
  font-size: 24px;
  color: rgba(0, 0, 0, 0.87);
  padding: 14px 1em 0;
}
.swal-content {
  font-family: "Kanit";
  font-size: 16px;
  color: #0000008a;
}

.swal-actions {
  margin: 14px auto 0;
}
.swal-confirm-button,
.swal-cancel-button {
  font-family: "Kanit";
  font-size: 15px !important;
  width: 100px;
  height: 42px;
  padding: 0;
  margin: 4px 12px;
  border-radius: 30px !important;
}
.swal-cancel-button {
  border: 2px solid #9e9e9e !important;
  color: #9e9e9e !important;
}
