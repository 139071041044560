body {
  margin: 0;
  background-color: #f5f6f8;
  font-family: "Kanit", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.datepicker-calendar-container {
  overflow: hidden;

  background: #ffffff;
  border: 1.5px solid #8c499c;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.border-red {
  border-color: red;
}

.datepicker-calendar-container .react-datepicker__header {
  background: #ffffff;
  border: none;
}

.datepicker-calendar-container .react-datepicker__day {
  font-family: inherit;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  color: black;
  padding: 4px;
}

.react-datepicker__day--in-selecting-range {
  background-color: #ebdfff;
  color: black;
}

.react-datepicker__month--selecting-range
  .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range
  .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range
  .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: #ebdfff;
  color: black;
}
.react-datepicker__day--outside-month {
  padding: 0 4px !important;
}

.datepicker-calendar-container .react-datepicker__day--keyboard-selected {
  border-radius: 50%;
  background-color: #8c499c;
  color: white;
}

.datepicker-calendar-container .react-datepicker__day--selected {
  border-radius: 50%;
  background-color: #8c499c;
  color: white;
}

.react-datepicker__day--in-range:hover {
  background-color: #8c499c;
  color: white;
}

.react-datepicker__day--selecting-range-end:hover {
  background-color: #8c499c;
  color: white;
}

.react-datepicker__day--range-start.react-datepicker__day--selecting-range-start:not(:hover):after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 60%;
  background: #8c499c30;
}

.react-datepicker__day--range-end.react-datepicker__day--selecting-range-end:not(:hover):before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 60%;
  bottom: 0;
  left: 0;
  background: #8c499c30;
}

.react-datepicker__day--selecting-range-start {
  border-radius: 50%;
  background-color: #8c499c;
  color: white !important;
  position: relative;
  z-index: 9;
}
.react-datepicker__day--selecting-range-end {
  border-radius: 50%;
  background-color: #8c499c;
  color: white !important;
  position: relative;
  z-index: 9;
}

.react-datepicker__day--in-selecting-range:hover {
  border-radius: 0;
  background-color: #ebdfff;
  color: black !important;
  /* background-color: #8c499c;
  color: white !important; */
}

#influencer-search::-webkit-scrollbar {
  width: 5px;
}

#influencer-search::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#influencer-search::-webkit-scrollbar-thumb {
  background: #888;
}

#influencer-search::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#menu-tabs .MuiTabs-indicator {
  display: none;
}
