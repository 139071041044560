#input-file {
  display: none;
}

.label-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    gray;

  width: 100px;
  height: 100px;

  border: 1.5px dashed gray;
  box-sizing: border-box;
  border-radius: 4px;
  color: gray;
}

/* button {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: white;
  padding: 0;
} */

image {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
}

/* .videoCancleIcon{
  position: "absolute";
  top: "-15px";
  right: "-15px";
} */

.label-video-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    cursor: pointer;
  
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      gray;
  
    width: 150px;
    height: 150px;
  
    border: 1px dashed #9e9e9e;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 10px;
    color: gray;
}