.carousel-main {
  display: grid;
}
.carousel-main .carousel-slider .slider-wrapper > ul.slider > li.slide {
  padding-inline: 2px;
}

.indicator-style {
  width: 9px;
  height: 9px;
}
@media only screen and (min-width: 600px) {
  .indicator-style {
    width: 11px;
    height: 11px;
  }
}
@media only screen and (min-width: 1200px) {
  .indicator-style {
    width: 12px;
    height: 12px;
  }
}
@media only screen and (min-width: 1536px) {
  .indicator-style {
    width: 14px;
    height: 14px;
  }
}
/* .carousel-item-stack {
  width: inherit;
  padding-bottom: 44px;
} */
/* .carousel-item-image {
  height: 100%;
  width: inherit;
} */
/* .carousel-item-icon {
  font-size: 28px;
  padding-right: "4px";
} */
