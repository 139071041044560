#input-file {
  display: none;
}

.label-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
  cursor: pointer;

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    gray;

  width: 150px;
  height: 150px;

  border: 1.5px dashed gray;
  box-sizing: border-box;
  border-radius: 4px;
  color: gray;
}

.iconImages {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconImages img {
  display:'flex';
  width: 70px;
  opacity: 0.5;
  margin: 10px auto -16px auto;
}

.button {
  position: absolute;
  top: -125px;
  right: -78px;
  background-color: white;
  padding: 0;
}

.image {
  height: 100px;
  width: 100px;
  object-fit: cover as cover;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
}

.inputButton {
  position: absolute;
  top: -20px;
  left: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
}

.inputButtonData {
  position: absolute;
  top: -30px;
  left: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
}